import React from 'react';
import { BASE_URL } from '../../shared/constants';
import { renderPrice } from '../../utils/adminOrderService';
import { Link } from 'react-router-dom';

const OrderItemMobile = ({ item, setModalImage, handleEditClick, isLast }) => {
  const handleImageClick = (imagePath) => {
    setModalImage(imagePath);
  };

  return (
    <div className='row pt-1 align-items-center'>
      <div className='col-3 d-flex align-items-center'>
        {item.images && item.images.length > 0 && (
          (() => {
            const image = item.images.find(image => image.sortOrder === 0);
            if (image) {
              return (
                <img
                  src={BASE_URL + '/api/v1/product/image/' + image.imagePath}
                  alt={item.name}
                  className='img-fluid'
                  onClick={() => handleImageClick(image.imagePath)}
                  style={{ cursor: 'pointer' }}
                  data-toggle='modal'
                  data-target='#imageModal'
                />
              );
            }
            return null; // Якщо зображення з sortOrder 0 не знайдено, нічого не відображати
          })()
        )}
      </div>

      <div className='col-7'>
        <div>
          <b>{item.name}</b>
          {item.state && item.state.databaseValue && item.state.databaseValue === 'PREORDER' && (
            (<span className="font-weight-bold"> [В передзамовленні]</span>)
          )}
          {item.state && item.state.databaseValue && item.state.databaseValue !== 'PREORDER' && item.parentProductName && (
            (<span className="font-weight-bold"> [{item.parentProductName}]</span>)
          )}
            <Link to={'/product/' + item.productId} style={{ color: 'black' }}>
              <i className='bi bi-forward-fill'></i>
            </Link>
          {item.productCode && (
            <div> <span className='text-muted'>Артикул: <b>{item.productCode}</b> </span></div>
          )}
          {item.unsentQuantity !== undefined && item.unsentQuantity !== null && (
            <div><span className='text-muted'>Залишок: <b>{item.unsentQuantity}шт</b> </span></div>
          )}
        </div>
        <div className='d-flex justify-content-center align-items-center pt-2'>
          <span className='p-0 m-0 font-weight-bold'
                onClick={() => handleEditClick(item)}
                data-toggle='modal'
                data-target='#changeDiscountModal'>
            {renderPrice(item)}
          </span>
          <span className='mx-1'>х</span>
          <span className={`${item.quantity > 1 ? 'text-danger font-weight-bold' : ''}`}
                onClick={() => handleEditClick(item)}
                data-toggle='modal'
                data-target='#editQuantityModal'>
            {item.quantity}шт
          </span>
        </div>
      </div>



      <div className='col-2 d-flex justify-content-center align-items-center'>
        <a
          className='cursor-pointer text-dark pr-2'
          onClick={() => handleEditClick(item)}
          data-toggle='modal'
          data-target='#editProductOrderModal'>
          <i className='bi bi-pencil-fill'></i>
        </a>
        <a
          className='cursor-pointer text-danger'
          onClick={() => handleEditClick(item)}
          data-toggle='modal'
          data-target='#confirmDeleteModal'>
          <i className='bi bi-trash-fill'></i>
        </a>
      </div>
      {!isLast && <div className="w-100 border-bottom mt-2"></div>}
    </div>
  );
};

export default OrderItemMobile;
